import React from "react";
import { Switch, NavLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
	getAssignment,
	getAssignmentDefaults,
} from "../../../queries/admin-queries";
import { getMe, userQuerySettings } from "../../../queries/user";

import ProtectedRoute from "../../../routes/protected-route";
import Navigation from "../../../components/navigation/navigation";
import ScrollArea from "../../../components/scroll-area/scroll-area";

import AssignmentSummary from "./assignment-summary";
import AssignmentDetailSubmissions from "./assignment-detail-submissions";
import AssignmentDetailReviews from "./assignment-detail-reviews";
import AssignmentDetailFeedbacks from "./assignment-detail-feedbacks";
import AssignmentDetailOral from "./assignment-detail-oral";
import AssignmentDetailOralSubmission from "./assignment-detail-oral-submission";
import AssignmentDetailOralVideo from "./assignment-detail-oral-submission-video";
import AssignmentDetailOralTranscript from "./assignment-detail-oral-submission-transcript";
import AssignmentDetailPublish from "./assignment-detail-publish";
import AssignmentDetailComplete from "./assignment-detail-complete";
import AssignmentDetailTeams from "./assignment-detail-teams";
import AssignmentDetailViewTeams from "./assignment-detail-view-teams";
import AssignmentDetailEditTeam from "./assignment-detail-edit-team";
import AssignmentDetailReports from "./assignment-detail-reports";
import AssignmentReportsLeagues from "./assignment-detail-reports-leagues";
import AssignmentReportsStudents from "./assignment-detail-reports-students";
import AssignmentReportsParticipation from "./assignment-detail-reports-participation";
import AssignmentReportsResults from "./assignment-detail-reports-results";
import AssignmentReportsAICriticalThinking from "./assignment-detail-reports-ai-critical-thinking";
import AssignmentReportsAIWriting from "./assignment-detail-reports-ai-writing";
import StudentResults from "./student-results";
import StudentCompareResults from "./student-compare";
import StudentFeedbackResults from "./student-feedback";
import LeagueDetail from "./league-detail";
import Loading from "../../../components/loading/loading";
import styles from "./assignment-detail.module.scss";

const AssignmentDetail = (props) => {
	const { match } = props;
	const { id } = useParams();
	const { path } = match;
	const {
		isLoading,
		isSuccess,
		isError,
		data: assignmentDetail,
		error,
	} = useQuery(
		["admin-assignment", id],
		() => getAssignment(id),
		getAssignmentDefaults,
	);
	const user = useQuery("me", getMe, userQuerySettings);

	let title = "";
	if (isSuccess) {
		if (assignmentDetail.type === "team") {
			title = `Team Assignment: ${assignmentDetail.title}`;
		} else {
			title = assignmentDetail.title;
		}
	}

	if (isLoading || user.isLoading) {
		return <Loading />;
	}

	let reportsClasses = styles.tabLink;
	if (user.data.is_demo) {
		reportsClasses = styles.reportDemoTabLink;
	}

	return (
		<div className={styles.container}>
			<Navigation title={title} hasBackButton />
			<ScrollArea>
				<div className={styles.tabsContainer}>
					<div className={styles.tabs}>
						<NavLink
							exact
							className={styles.tabLink}
							activeClassName={styles.tabLinkActive}
							to={`/assignments/${id}/`}
						>
							Summary
						</NavLink>
						<NavLink
							exact
							className={styles.tabLink}
							activeClassName={styles.tabLinkActive}
							to={`/assignments/${id}/submissions`}
						>
							Submissions
						</NavLink>
						<NavLink
							exact
							className={styles.tabLink}
							activeClassName={styles.tabLinkActive}
							to={`/assignments/${id}/reviews`}
						>
							Reviews
						</NavLink>
						{!assignmentDetail.skip_feedback_state && (
							<NavLink
								exact
								className={styles.tabLink}
								activeClassName={styles.tabLinkActive}
								to={`/assignments/${id}/feedbacks`}
							>
								Score Reviews
							</NavLink>
						)}
						{assignmentDetail.has_oral_assignment && (
							<NavLink
								exact
								className={styles.tabLink}
								activeClassName={styles.tabLinkActive}
								to={`/assignments/${id}/oral`}
							>
								Timed Oral
							</NavLink>
						)}
						<NavLink
							exact
							className={reportsClasses}
							activeClassName={styles.tabLinkActive}
							to={`/assignments/${id}/reports`}
						>
							Reports
						</NavLink>
					</div>
				</div>
				<div className={styles.content}>
					<Switch>
						<ProtectedRoute
							exact
							path={`${path}`}
							component={AssignmentSummary}
						/>
						<ProtectedRoute
							path={`${path}/submissions`}
							component={AssignmentDetailSubmissions}
						/>
						<ProtectedRoute
							path={`${path}/reviews`}
							component={AssignmentDetailReviews}
						/>
						<ProtectedRoute
							path={`${path}/feedbacks`}
							component={AssignmentDetailFeedbacks}
						/>
						<ProtectedRoute
							exact
							path={`${path}/oral/submission/video/:sectionMemberID([-a-f0-9]{36})/:submissionID([0-9]+)`}
							component={AssignmentDetailOralVideo}
						/>
						<ProtectedRoute
							path={`${path}/oral/submission/transcript/:sectionMemberID([-a-f0-9]{36})/:oralSubmissionID([0-9])`}
							component={AssignmentDetailOralTranscript}
						/>
						<ProtectedRoute
							path={`${path}/oral/submission/detail/:sectionMemberID([-a-f0-9]{36})`}
							component={AssignmentDetailOralSubmission}
						/>
						<ProtectedRoute
							path={`${path}/oral`}
							component={AssignmentDetailOral}
						/>
						<ProtectedRoute
							path={`${path}/reports/students`}
							component={AssignmentReportsStudents}
						/>
						<ProtectedRoute
							path={`${path}/reports/participation`}
							component={AssignmentReportsParticipation}
						/>
						<ProtectedRoute
							path={`${path}/reports/results/:sectionMemberID([-a-f0-9]{36})/feedback`}
							component={StudentFeedbackResults}
						/>
						<ProtectedRoute
							path={`${path}/reports/results/:sectionMemberID([-a-f0-9]{36})/compare`}
							component={StudentCompareResults}
						/>
						<ProtectedRoute
							path={`${path}/reports/results/:sectionMemberID([-a-f0-9]{36})`}
							component={StudentResults}
						/>
						<ProtectedRoute
							path={`${path}/reports/results`}
							component={AssignmentReportsResults}
						/>
						<ProtectedRoute
							path={`${path}/reports/criticalthinking`}
							component={AssignmentReportsAICriticalThinking}
						/>
						<ProtectedRoute
							path={`${path}/reports/writing`}
							component={AssignmentReportsAIWriting}
						/>
						<ProtectedRoute
							exact
							path={`${path}/reports/league/:leagueID([-a-f0-9]{36})`}
							component={LeagueDetail}
						/>
						<ProtectedRoute
							path={`${path}/reports/leagues`}
							component={AssignmentReportsLeagues}
						/>
						<ProtectedRoute
							path={`${path}/reports`}
							component={AssignmentDetailReports}
						/>
						<ProtectedRoute
							path={`${path}/publish`}
							component={AssignmentDetailPublish}
						/>
						<ProtectedRoute
							path={`${path}/complete`}
							component={AssignmentDetailComplete}
						/>
						<ProtectedRoute
							path={`${path}/teams`}
							component={AssignmentDetailTeams}
						/>
						<ProtectedRoute
							path={`${path}/view/teams`}
							component={AssignmentDetailViewTeams}
						/>
						<ProtectedRoute
							path={`${path}/edit-team/:leagueID([-a-f0-9]{36})`}
							component={AssignmentDetailEditTeam}
						/>
					</Switch>
				</div>
			</ScrollArea>
		</div>
	);
};

AssignmentDetail.propTypes = {};

export default AssignmentDetail;
