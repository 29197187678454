import classNames from 'classnames';

const CurrentState = ({ state }) => {
  // Handle colors
  const classes = classNames('rounded py-2 px-4 text-lg text-white bg-gray-800 uppercase', {
    'bg-gray-400': state === 'pending',
    'bg-green-500': state === 'active',
    'bg-red-500': state === 'completed',
  });

  let stateText = state;

  if (stateText === 'active') {
    stateText = 'PUBLISHED';
  }

  return (
    <span className={classes}>{stateText}</span>
  );
};

export default CurrentState;
