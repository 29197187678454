import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import HelpLabel from '../../../components/help-label';
import CreateNewAssignmentButton from '../create-new-assignment-button';
import CreateNewOralAssignmentButton from '../create-new-oral-assignment-button';
import styles from './section-detail.module.scss';
import { useUser } from '../../../queries/user';
import { useSection } from '../../../queries/admin-queries';
import { getFormHelp, formHelpQuerySettings } from '../../../queries/help';

const SectionDetailOverview = (props) => {
  const { id } = useParams();
  const section = useSection(id);
  const user = useUser();
  const help = useQuery(['form-help', 'section-overview'], () => getFormHelp('section-overview'), formHelpQuerySettings);

  if (user.isLoading || section.isLoading || help.isLoading) {
    return <div>Loading...</div>;
  }

  let showEditLink = !user.data.is_hbp && !user.data.is_lti;
  let isDemo = user.data.is_demo;

  return (
    <React.Fragment>
      <div className={styles.actions}>

        <div className="container">
          {showEditLink && (
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/edit`, state: { modal: true } }}
            >
              Edit Section
            </Link>
          )}
        </div>
      </div>
      <div className="container">
        <div className={styles.header}>
          <h3>
            {section.data.name}
          </h3>
        </div>
        {!isDemo && (
          <div className="w-full">
            <div className="my-6 grid grid-cols-4 gap-6 items-start">
              <CreateNewAssignmentButton sectionID={id} type="normal" isHBP={user.data.is_hbp} label="Create Individual Assignment" />
              <div className="pl-6 col-span-3">
                <h3 className="font-semibold">Create Individual Assignment</h3>
                <ul className="list-disc my-2">
                  <li className="ml-8">One submission per student</li>
                  <li className="ml-8">Submissions are distributed for peer review</li>
                  <li className="ml-8">Optional: Add timed oral assignment after peer review (similar to standalone option below)</li>
                </ul>
              </div>
            </div>
            <div className="my-6 grid grid-cols-4 gap-6 items-start">
              <CreateNewAssignmentButton sectionID={id} type="team" isHBP={user.data.is_hbp} label="Create Team Assignment" />
              <div className="pl-6 col-span-3">
                <h3 className="font-semibold">Create Team Assignment</h3>
                <ul className="list-disc my-2">
                  <li className="ml-8">Submissions are distributed for peer review</li>
                  <li className="ml-8">One submission per team</li>
                </ul>
              </div>
            </div>
            <div className="my-6 grid grid-cols-4 gap-6 items-start">
              <CreateNewOralAssignmentButton sectionID={id} label="Create Timed Oral Assignment" />
              <div className="pl-6 col-span-3">
                <h3 className="font-semibold">Create Standalone Timed Oral Assignment</h3>
                <ul className="list-disc my-2">
                  <li className="ml-8">Students have 5 minutes to prepare and record responses</li>
                  <li className="ml-8">Instructor receives video and transcript</li>
                  <li className="ml-8">Optional GenAI evaluation of transcript</li>
                  <li className="ml-8">No peer review component</li>
                </ul>
              </div>
            </div>
            <p className="mt-2 mb-6">
              Note: Submissions can include written work, podcasts, videos, and other multimedia.
            </p>
          </div>
        )}
        {isDemo && (
          <div className="my-6 bg-blue-100 border border-blue-500 p-6">
            <p className="my-2">Creating new Assignments is disabled in the demo.</p>
          </div>
        )}
        <div className={styles.content}>
          <p>
            {section.data.description}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};


export default SectionDetailOverview;
