import Cookies from "js-cookie";

class RequestError extends Error {
  constructor(message, statusCode, data) {
    super(message);
    this.name = "RequestError";
    this.statusCode = statusCode;
    this.data = data;
  }
}

export const getCSRFToken = async () => {
  const resp = await fetch("/api/csrf/", {
    method: "GET",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await resp.json();
  return data.token;
};

export const jsonHeaders = async ({ includeCsrf = true, formData = false }) => {
  const headers = new Headers();

  headers.append("Accept", "application/json");
  if (!formData) {
    headers.append("Content-Type", "application/json");
  }

  if (includeCsrf === true) {
    headers.append("X-CSRFToken", await getCSRFToken());
  }

  return headers;
};

export const makeRequest = async (url, method, headers, body) => {
  headers = headers || new Headers();
  body = body || null;

  const resp = await fetch(url, {
    method: method,
    credentials: "same-origin",
    headers: headers,
    body: body,
  });

  if (!resp.ok) {
    const error = new RequestError(resp.statusText);
    error.statusCode = resp.status;
    error.data = await resp.json();
    throw error;
  }

  return resp;
};

export const getApiRequest = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data; // Ensure this is the correct part of the response you need
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const postApiRequest = async (url, data) => {
  const resp = await makeRequest(
    url,
    "POST",
    await jsonHeaders(true),
    JSON.stringify(data),
  );
  return resp.json();
};

export const postFormRequest = async (url, data) => {
  const resp = await makeRequest(
    url,
    "POST",
    await jsonHeaders(true, true),
    data,
  );
  return resp.json();
};

export const putApiRequest = async (url, data) => {
  const resp = await makeRequest(
    url,
    "PUT",
    await jsonHeaders(true),
    JSON.stringify(data),
  );
  return resp.json();
};

export const deleteApiRequest = async (url) => {
  const resp = await makeRequest(url, "DELETE", await jsonHeaders(true));
  if (resp.status !== 204) {
    return resp.json();
  }
  return {};
};

export const patchApiRequest = async (url, data) => {
  const resp = await makeRequest(
    url,
    "PATCH",
    await jsonHeaders(true),
    JSON.stringify(data),
  );
  return resp.json();
};
