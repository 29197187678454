import React from 'react';
import { Route } from 'react-router';
import { Spinner } from 'react-redux-spinner';
import PropTypes from 'prop-types';

import { spring, AnimatedSwitch } from '../common/react-router-transition';
import ProtectedRoute from './protected-route';
import Login from '../views/login/login';
import Logout from '../views/login/logout';
import ForgotPassword from '../views/login/forgot-password';
import ForgotPasswordConfirm from '../views/login/forgot-password-confirm';
import Dashboard from '../views/dashboard/dashboard-container';
import Demo from '../views/demo';
import Help from '../views/help/';
import Support from '../views/support/';

// Admin Imports
import CourseCreateContainer from '../views/admin/course-create/course-create-container';
import CourseDetail from '../views/admin/course-detail/course-detail';
import SectionCreateContainer from '../views/admin/section-create/section-create-container';
import SectionDetail from '../views/admin/section-detail/section-detail';
import AssignmentBulkCreateContainer from '../views/admin/assignment-bulk-create/assignment-bulk-create-container';
import AssignmentEditView from '../views/admin/assignment-edit';
import AssignmentDetail from '../views/admin/assignment-detail/assignment-detail';
import OralDetail from '../views/admin/oral-detail/oral-detail';
import ParticipantsCreateContainer from '../views/admin/participants-create/participants-create-container';
import ParticipantCreateContainer from '../views/admin/participant-create/participant-create-container';
import ParticipantDetailContainer from '../views/admin/participant-detail/participant-detail-container';
import SubmissionDetail from '../views/admin/submission-detail/submission-detail';
import ReviewDetailContainer from '../views/admin/review-detail/review-detail-container';
import FeedbackDetailContainer from '../views/admin/feedback-detail/feedback-detail-container';

// Stundents Imports
import StudentAssignmentDetail from '../views/student/assignment-detail/assignment-detail';
import StudentOralAssignmentDetail from '../views/student/oral-detail/oral-detail';
import StudentOralAssignmentStarted from '../views/student/oral-detail/oral-start';
import StudentOralAssignmentPractice from '../views/student/oral-detail/oral-practice';
import StudentEmbeddedOralAssignmentDetail from '../views/student/oral-embedded/oral-detail';
import StudentEmbeddedOralAssignmentStarted from '../views/student/oral-embedded/oral-start';
import StudentEmbeddedOralAssignmentPractice from '../views/student/oral-embedded/oral-practice';
import ReviewFeedbackCreateContainer from '../views/student/review-feedback-create/review-feedback-create-container';
import SubmissionCreateContainer from '../views/student/submission-create/submission-create-container';
import SubmissionLateSubmit from '../views/student/submission-create/submission-late-submit';
import OralCreate from '../views/student/oral-create/oral-create';
import OralRecord from '../views/student/oral-create/oral-record';
import ReviewCreateContainer from '../views/student/review-create/review-create-container';
import Settings from '../views/student/settings/settings';
import Results from '../views/student/results/results';
import Submission from '../views/student/results/submission';
import CompareResults from '../views/student/results/compare';
import FeedbackResults from '../views/student/results/feedback';

import NoMatch from '../views/error/error';

import Notifications from '../components/notifications/notifications';

import styles from '../scss/app.module.scss';


function mapStyles(routerStyles) {
  return {
    opacity: routerStyles.opacity,
    transform: `scale(${routerStyles.scale})`,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

class Routes extends React.Component {
  previousLocation = this.props.location;

  bounceTransition = {
    atEnter: {
      opacity: 0,
      scale: 1.2,
    },
    atLeave: {
      opacity: bounce(0),
      scale: bounce(0.8),
    },
    atActive: {
      opacity: bounce(1),
      scale: bounce(1),
    },
  };

  switchTransition = {
    atEnter: {
      offset: 100
    },
    atLeave: {
      offset: -100
    },
    atActive: {
      offset: 0
    },
  };

  componentWillUpdate(nextProps) {
    const { location } = this.props;

    if (
      nextProps.history.action !== 'POP'
      && (!location.state || !location.state.modal)
    ) {
      this.previousLocation = location;
    }

    if (nextProps.history.action === 'POP'
      || (
        nextProps.history.location.state
        && nextProps.history.location.state.backTransition
      )) {
      this.switchTransition = {
        atEnter: {
          offset: -100
        },
        atLeave: {
          offset: 100
        },
        atActive: {
          offset: 0
        },
      };
    } else {
      this.switchTransition = {
        atEnter: {
          offset: 100
        },
        atLeave: {
          offset: -100
        },
        atActive: {
          offset: 0
        },
      };
    }
  }

  render() {
    return (
      <Route render={(props) => {
        const isModal = !!(
          props.location.state
          && props.location.state.modal
          && this.previousLocation !== props.location
        );
        return (
          <React.Fragment>
            <AnimatedSwitch
              atEnter={this.bounceTransition.atEnter}
              atLeave={this.bounceTransition.atLeave}
              atActive={this.bounceTransition.atActive}
              mapStyles={mapStyles}
              location={props.location}
              className={styles.modalContainer}
            >
              {isModal ? <ProtectedRoute path="/courses/create" component={CourseCreateContainer} /> : null}
              {isModal ? <ProtectedRoute path="/courses/:id([-a-f0-9]{36})/edit" component={CourseCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/sections/create/:courseID([-a-f0-9]{36})" component={SectionCreateContainer} /> : null}
              {isModal ? <ProtectedRoute path="/sections/create" component={SectionCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/sections/:id([-a-f0-9]{36})/edit" component={SectionCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/sections/:id([-a-f0-9]{36})/participants/create" component={ParticipantsCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/sections/:id([-a-f0-9]{36})/participant/create" component={ParticipantCreateContainer} /> : null}
              {isModal ? <ProtectedRoute path="/assignments/:id([-a-f0-9]{36})/edit" component={AssignmentEditView} /> : null}
              {isModal ? <ProtectedRoute path="/assignments/bulk-create/:id([-a-f0-9]{36})" component={AssignmentEditView} /> : null}
              {isModal ? <ProtectedRoute path="/assignments/:id([-a-f0-9]{36})/submissions/create" component={SubmissionCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/submissions/:id([-a-f0-9]{36})/reviews/create" component={ReviewCreateContainer} /> : null}
              {isModal ? <ProtectedRoute exact path="/submissions/:id([-a-f0-9]{36})/review_feedbacks/create" component={ReviewFeedbackCreateContainer} /> : null}
              <ProtectedRoute path="/courses/create" component={CourseCreateContainer} />
              <ProtectedRoute path="/courses/:id([-a-f0-9]{36})/edit" component={CourseCreateContainer} />
            </AnimatedSwitch>
            <AnimatedSwitch
              atEnter={this.switchTransition.atEnter}
              atLeave={this.switchTransition.atLeave}
              atActive={this.switchTransition.atActive}
              mapStyles={(styles) => ({
                transform: `translateX(${styles.offset}%)`,
              })}
              location={isModal ? this.previousLocation : props.location}
            >
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/password-confirm/:uid/:token/" component={ForgotPasswordConfirm} />
              <Route exact path="/password-reset/" component={ForgotPassword} />
              <Route path="/help/" component={Help} />
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/demo/:id([-a-f0-9]{36})" component={Demo} />
              <ProtectedRoute exact path="/demo" component={Demo} />
              <ProtectedRoute exact path="/settings" component={Settings} />
              <ProtectedRoute exact path="/support" component={Support} />

              { /* STUDENTS VIEWS */}
              <ProtectedRoute exact path="/my-assignments/:id([-a-f0-9]{36})" component={StudentAssignmentDetail} />
              <ProtectedRoute exact path="/my-oral/:id([-a-f0-9]{36})/:questionID/started" component={StudentEmbeddedOralAssignmentStarted} />
              <ProtectedRoute exact path="/my-oral/:id([-a-f0-9]{36})/practice" component={StudentEmbeddedOralAssignmentPractice} />
              <ProtectedRoute exact path="/my-oral/:id([-a-f0-9]{36})" component={StudentEmbeddedOralAssignmentDetail} />
              <ProtectedRoute exact path="/my-oral-assignments/:id([-a-f0-9]{36})/:questionID/started" component={StudentOralAssignmentStarted} />
              <ProtectedRoute exact path="/my-oral-assignments/:id([-a-f0-9]{36})/practice" component={StudentOralAssignmentPractice} />
              <ProtectedRoute exact path="/my-oral-assignments/:id([-a-f0-9]{36})" component={StudentOralAssignmentDetail} />
              <ProtectedRoute exact path="/results/:id([-a-f0-9]{36})/compare" component={CompareResults} />
              <ProtectedRoute exact path="/results/:id([-a-f0-9]{36})/feedback" component={FeedbackResults} />
              <ProtectedRoute exact path="/results/:id([-a-f0-9]{36})/submission" component={Submission} />
              <ProtectedRoute exact path="/results/:id([-a-f0-9]{36})" component={Results} />
              <ProtectedRoute exact path="/assignments/:id([-a-f0-9]{36})/late-submit" component={SubmissionLateSubmit} />
              <ProtectedRoute exact path="/assignments/:id([-a-f0-9]{36})/submissions/create" component={SubmissionCreateContainer} />
              <ProtectedRoute exact path="/oral/:id([-a-f0-9]{36})/create" component={OralCreate} />
              <ProtectedRoute exact path="/oral/:id([-a-f0-9]{36})/record" component={OralRecord} />
              <ProtectedRoute exact path="/submissions/:id([-a-f0-9]{36})/reviews/create" component={ReviewCreateContainer} />
              <ProtectedRoute exact path="/submissions/:id([-a-f0-9]{36})/review_feedbacks/create" component={ReviewFeedbackCreateContainer} />

              { /* ADMIN VIEWS */}
              <ProtectedRoute exact path="/assignments/:id([-a-f0-9]{36})/edit" component={AssignmentEditView} />
              <ProtectedRoute path="/assignments/:id([-a-f0-9]{36})" component={AssignmentDetail} />
              <ProtectedRoute path="/oral/assignments/:id([-a-f0-9]{36})" component={OralDetail} />
              <ProtectedRoute exact path="/submissions/:id([-a-f0-9]{36})" component={SubmissionDetail} />
              <ProtectedRoute exact path="/reviews/:id([-a-f0-9]{36})" component={ReviewDetailContainer} />
              <ProtectedRoute exact path="/feedbacks/:id([-a-f0-9]{36})" component={FeedbackDetailContainer} />
              <ProtectedRoute path="/sections/:id([-a-f0-9]{36})" component={SectionDetail} />
              <ProtectedRoute path="/courses/:id([-a-f0-9]{36})" component={CourseDetail} />
              <ProtectedRoute path="/participants/:id([-a-f0-9]{36})" component={ParticipantDetailContainer} />

              <Route component={NoMatch} />
            </AnimatedSwitch>
            <Notifications />
            <Spinner />
          </React.Fragment>
        );
      }}
      />
    );
  }
}

Routes.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default Routes;
