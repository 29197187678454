import { useQuery } from "react-query";
import Cookies from "js-cookie";
import {
  getApiRequest,
  postApiRequest,
  postFormRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from "./base";

export const getAssignments = async () => {
  return getApiRequest("/api/student/assignments/");
};

export const getAssignmentSettings = {
  cacheTime: 60000,
  staleTime: 60000,
};

export const getAssignment = async (id) => {
  return getApiRequest(`/api/student/assignments/${id}/`);
};

export const getAssignmentSubmission = async (queryKey) => {
  return getApiRequest(`/api/student/assignments/${queryKey}/submission/`);
};

export const getAssignmentResults = async (queryKey) => {
  return getApiRequest(`/api/student/results/${queryKey}/`);
};

export const getAssignmentCompareResults = async (queryKey) => {
  return getApiRequest(`/api/student/results/compare/${queryKey}/`);
};

export const getAssignmentReviewFeedback = async (queryKey) => {
  return getApiRequest(`/api/student/results/feedback/${queryKey}/`);
};

export const getAssignmentMembers = async (id) => {
  return getApiRequest(`/api/student/assignments/${id}/team_members/`);
};

export const submitLateSubmission = async (assignmentID) => {
  return postApiRequest(`/api/student/late-submit/${assignmentID}/`);
};

export const getOral = async (id) => {
  return getApiRequest(`/api/student/assignments/${id}/oral/`);
};

export const startOral = async (id) => {
  return postApiRequest(`/api/oral/${id}/start/`, {});
};

export const uploadOral = async ({ id, videoBlob }) => {
  const data = new FormData();
  data.append("video", videoBlob, `oral-${id}.mp4`);

  console.log("uploading data");
  console.dir(videoBlob);
  const response = await fetch(`/api/oral/${id}/upload/`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: data,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const uploadStandAloneOral = async ({
  id,
  questionID,
  videoBlob,
  oralID,
}) => {
  const data = new FormData();
  data.append(
    "video_file",
    videoBlob,
    `oral-${id}-${oralID}-${questionID}.webm`,
  );

  console.log("uploading data");
  console.dir(videoBlob);
  const response = await fetch(
    `/api/v2/student/oral/upload/${id}/${questionID}/`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: data,
    },
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const uploadPracticeOral = async ({ id, videoBlob }) => {
  const data = new FormData();
  data.append("video_file", videoBlob, `oral-practice-${id}.mp4`);

  console.log("uploading data");
  console.dir(videoBlob);
  const response = await fetch(`/api/v2/student/oral/practice/${id}/`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: data,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const uploadFile = async ({ id, fileBlob, filename }) => {
  const data = new FormData();
  data.append("submission_file", fileBlob, filename);

  console.log("uploading data");
  console.dir(fileBlob);
  const response = await fetch(`/api/v2/student/oral/submission/${id}/`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: data,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentList = async () => {
  return getApiRequest("/api/v2/student/assignments/list/");
};

export const useAssignmentList = () => {
  return useQuery(["student-assignment-list"], getAssignmentList, {
    cacheTime: 20 * 1000,
    staleTime: 5 * 1000,
  });
};

export const getOralAssignment = async (id) => {
  return getApiRequest(`/api/v2/student/oral/${id}/`);
};

export const useOralAssignment = (id) => {
  return useQuery(
    ["student-oral-assignment", id],
    () => getOralAssignment(id),
    { cacheTime: 20 * 1000, staleTime: 5 * 1000 },
  );
};

export const startOralAssignment = async (id, question_id) => {
  return postApiRequest(`/api/v2/student/oral/${id}/${question_id}/start/`);
};

export const useStartedOralAssignment = (id, question_id) => {
  return useQuery(
    ["student-oral-assignment-started", id],
    () => startOralAssignment(id, question_id),
    { cacheTime: 10 * 1000, staleTime: 2 * 1000 },
  );
};

export const startOralAssignmentRecording = async (id, question_id) => {
  console.log("starting recording");
  console.log("id", id);
  console.log("question_id", question_id);
  return postApiRequest(
    `/api/v2/student/oral/${id}/${question_id}/start-recording/`,
  );
};

export const reportProblem = async (id, data) => {
  console.dir(data);
  const formData = new FormData();
  formData.append("problem", data.problem);
  formData.append("data", JSON.stringify(data.data));
  if (data.file && data.file[0]) {
    formData.append("file", data.file[0]);
  }

  const response = await fetch(`/api/v2/student/oral/problem/${id}/`, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    body: formData,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

// Embedded
export const getEmbeddedOralAssignment = async (id) => {
  return getApiRequest(`/api/v2/student/oral/embedded/${id}/`);
};

export const useEmbeddedOralAssignment = (id) => {
  return useQuery(
    ["student-embedded-oral-assignment", id],
    () => getEmbeddedOralAssignment(id),
    { cacheTime: 20 * 1000, staleTime: 5 * 1000 },
  );
};

export const uploadEmbeddedPracticeOral = async ({ id, videoBlob }) => {
  const data = new FormData();
  data.append("video_file", videoBlob, `oral-practice-${id}.mp4`);

  const response = await fetch(
    `/api/v2/student/oral/embedded/practice/${id}/`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: data,
    },
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const uploadEmbeddedOral = async ({
  id,
  questionID,
  videoBlob,
  oralID,
}) => {
  const data = new FormData();
  data.append(
    "video_file",
    videoBlob,
    `oral-${id}-${oralID}-${questionID}.webm`,
  );

  console.log("uploading data");
  console.dir(videoBlob);
  const response = await fetch(
    `/api/v2/student/oral/embedded/upload/${id}/${questionID}/`,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
      body: data,
    },
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const reportEmbeddedProblem = async (id, data) => {
  console.dir(data);
  return postApiRequest(`/api/v2/student/oral/embedded/problem/${id}/`, data);
};

export const startEmbeddedOralAssignment = async (id, question_id) => {
  return postApiRequest(
    `/api/v2/student/oral/embedded/${id}/${question_id}/start/`,
  );
};

export const useStartedEmbeddedOralAssignment = (id, question_id) => {
  return useQuery(
    ["student-oral-assignment-started", id],
    () => startEmbeddedOralAssignment(id, question_id),
    { cacheTime: 10 * 1000, staleTime: 2 * 1000 },
  );
};
