import React from "react";
import { Switch, NavLink, useParams } from "react-router-dom";
import { useOralAssignment } from "../../../queries/admin-queries";
import { useUser } from "../../../queries/user";

import ProtectedRoute from "../../../routes/protected-route";
import Navigation from "../../../components/navigation/navigation";
import ScrollArea from "../../../components/scroll-area/scroll-area";

import OralSummary from "./oral-summary";
import OralSubmissions from "./oral-submissions";
import OralSubmissionDetail from "./oral-submission-detail";
import OralSubmissionVideo from "./oral-submission-video";
import OralSubmissionTranscript from "./oral-submission-transcript";
import OralEdit from "./oral-edit";
import OralAddQuestion from "./oral-add-question";
import OralEditQuestion from "./oral-edit-question";
import Loading from "../../../components/loading/loading";
import styles from "./oral-detail.module.scss";

const OralDetail = (props) => {
  const { match } = props;
  const { id } = useParams();
  const { path } = match;
  const user = useUser();
  const assignment = useOralAssignment(id);

  if (user.isLoading || assignment.isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <Navigation title={assignment.data.title} hasBackButton />
      <ScrollArea>
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/oral/assignments/${id}/`}
            >
              Summary
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/oral/assignments/${id}/submissions`}
            >
              Submissions
            </NavLink>
          </div>
        </div>
        <div className={styles.content}>
          <Switch>
            <ProtectedRoute exact path={`${path}`} component={OralSummary} />
            <ProtectedRoute
              exact
              path={`${path}/submissions`}
              component={OralSubmissions}
            />
            <ProtectedRoute
              exact
              path={`${path}/submission/detail/:sectionMemberID([-a-f0-9]{36})`}
              component={OralSubmissionDetail}
            />
            <ProtectedRoute
              exact
              path={`${path}/submission/video/:sectionMemberID([-a-f0-9]{36})/:submissionID`}
              component={OralSubmissionVideo}
            />
            <ProtectedRoute
              exact
              path={`${path}/submission/transcript/:sectionMemberID([-a-f0-9]{36})/:submissionID`}
              component={OralSubmissionTranscript}
            />
            <ProtectedRoute
              exact
              path={`${path}/edit-question/:questionID`}
              component={OralEditQuestion}
            />
            <ProtectedRoute
              exact
              path={`${path}/new-question`}
              component={OralAddQuestion}
            />
            <ProtectedRoute exact path={`${path}/edit`} component={OralEdit} />
          </Switch>
        </div>
      </ScrollArea>
    </div>
  );
};

export default OralDetail;
