import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { useUser } from "../../../queries/user";
import {
	useOralAssignment,
	useOralSubmissionDetail,
} from "../../../queries/admin-queries";
import Loading from "../../../components/loading/loading";
import CurrentState from "./current-state";

const buttonClasses = classNames(
	"rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1",
);

const OralSubmissionVideo = (props) => {
	const { id, sectionMemberID, submissionID } = useParams();
	const history = useHistory();
	const user = useUser();
	const assignment = useOralAssignment(id);
	const submissions = useOralSubmissionDetail(id, sectionMemberID);

	if (user.isLoading || assignment.isLoading || submissions.isLoading) {
		return <Loading />;
	}

	console.log("submissions", submissions.data);
	console.log("submissionID", submissionID);
	const sub = submissions.data.find((s) => s.id == submissionID);
	console.log("submission", sub);
	return (
		<React.Fragment>
			<div className="flex flex-row items-center">
				<h1 className="my-4 text-2xl font-bold flex-grow">
					Submission Video - {sub.author_first_name} {sub.author_last_name}
				</h1>
				<span className="flex-initial mx-2">
					<CurrentState state={assignment.data.state} />
				</span>
			</div>
			<hr className="border-1 border-solid border-black my-4" />
			<div className="mt-6">
				<div className="w-full flex items-center justify-center">
					<video src={sub.video_file} width="800" height="600" controls />
				</div>
			</div>
			<div className="mt-12">
				<button
					className={buttonClasses}
					onClick={() =>
						history.push(
							`/oral/assignments/${id}/submission/detail/${sectionMemberID}`,
						)
					}
				>
					Return to Submission Details
				</button>
			</div>
		</React.Fragment>
	);
};

export default OralSubmissionVideo;
