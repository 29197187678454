import React, { useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import { Link, useParams, useHistory } from "react-router-dom";
import {
	useForm,
	useFormContext,
	FormProvider,
	Controller,
	useWatch,
	useController,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useMutation, useQueryClient } from "react-query";
import { useUser } from "../../../queries/user";
import {
	useOralAssignment,
	updateOralAssignment,
} from "../../../queries/admin-queries";
import Loading from "../../../components/loading/loading";
import CurrentState from "./current-state";

const buttonClasses = classNames(
	"rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1",
);
const greenButtonClasses = classNames(
	"rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1",
);

const DateInput = ({ label, name, value = undefined, invisible = false }) => {
	const [currentDate, setCurrentDate] = useState(new Date());
	const {
		register,
		setValue,
		control,
		formState: { errors },
	} = useFormContext();
	const errorMessage = errors[name]?.message?.toString();

	useEffect(() => {
		if (value) {
			setValue(name, moment(value).toDate());
			setCurrentDate(moment(value).toDate());
		}
	}, [value]);

	const handleChange = (date) => {
		if (date) {
			setValue(name, date);
			setCurrentDate(moment(value).toDate());
		}
	};

	const outerClasses = classNames("w-full flex items-center", {
		invisible: invisible,
	});

	return (
		<div className={outerClasses}>
			<label
				htmlFor={`id_${name}`}
				className="w-1/4 block text-sm/6 font-semibold text-gray-900"
			>
				{label}
			</label>
			<div className="mt-4 w-full">
				<Controller
					control={control}
					name={name}
					render={({ field }) => (
						<DatePicker
							id={`id_${name}`}
							selected={field.value}
							onChange={(date) => field.onChange(date)}
							className="px-2 py-1 w-64"
							showTimeSelect={true}
							todayButton="Today"
							timeFormat="HH:mm"
							dateFormat="yyyy-MM-dd HH:mm"
						/>
					)}
				/>
			</div>
			{errorMessage && (
				<p id="email-error" className="mt-2 text-sm text-red-600">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

const Input = ({
	label,
	name,
	type = "text",
	placeholder = undefined,
	value = undefined,
}) => {
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext();
	const errorMessage = errors[name]?.message?.toString();

	useEffect(() => {
		if (value) {
			setValue(name, value);
		}
	}, [value]);

	return (
		<div className="w-full">
			<label
				htmlFor={`id_${name}`}
				className="block text-sm/6 font-semibold text-gray-900"
			>
				{label}
			</label>
			<div className="mt-6 w-full">
				<input
					id={`id_${name}`}
					name={name}
					type={type}
					placeholder={placeholder}
					className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
					{...register(name)}
				/>
			</div>
			{errorMessage && (
				<p id="email-error" className="mt-2 text-sm text-red-600">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

const Questions = ({ assignment }) => {
	const history = useHistory();

	const handleAdd = () => {
		history.push(`/oral/assignments/${assignment.data.id}/new-question`);
	};

	return (
		<div className="my-6">
			<div className="flex flex-row items-center">
				<h3 className="text-2xl font-semibold my-6 flex-grow">Questions</h3>
				<div className="flex-initial mx-2">
					<button className={buttonClasses} onClick={handleAdd}>
						Add Question
					</button>
				</div>
			</div>
			<hr className="border-1 border-solid border-black my-4" />
			{assignment.data.questions.length === 0 && (
				<p className="my-8 border border-blue-300 bg-blue-100 p-4">
					No questions have been added yet!
				</p>
			)}
			{assignment.data.questions.map((question, index) => (
				<div
					className="my-6 border border-gray-400 bg-gray-200 p-4"
					key={`question-${index}`}
				>
					<div className="flex flex-inline justify-between items-center">
						<h4 className="text-xl font-semibold my-2">
							Question #{index + 1}
						</h4>
						<button
							className="flex flex-inline space-x-2 hover:text-gray-600"
							onClick={() =>
								history.push(
									`/oral/assignments/${assignment.data.id}/edit-question/${question.id}`,
								)
							}
						>
							<svg
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
								/>
							</svg>
							<span>Edit</span>
						</button>
					</div>
					<p className="my-2">
						{question.question_type.name} - {question.question_type.explanation}
					</p>
					<span className="text-xl italic my-2">{question.question_text}</span>
				</div>
			))}
		</div>
	);
};

const OralEdit = (props) => {
	const { id } = useParams();
	const history = useHistory();
	const user = useUser();
	const assignment = useOralAssignment(id);
	const queryClient = useQueryClient();

	const form = useForm();
	const mutation = useMutation("update-oral-assignment", (data) =>
		updateOralAssignment(id, data),
	);

	if (user.isLoading || assignment.isLoading) {
		return <Loading />;
	}

	const allowLateWork = useWatch({
		control: form.control,
		name: "allow_late_work",
		defaultValue: assignment.data.allow_late_work,
	});

	const selectedQuestionType = useWatch({
		control: form.control,
		name: "question_type",
		defaultValue: assignment.data.question_type,
	});

	useEffect(() => {
		form.setValue("title", assignment.data.title);
		form.setValue("sends_email", assignment.data.sends_email);
		form.setValue("allow_late_work", assignment.data.allow_late_work);
		form.setValue("require_submission", assignment.data.require_submission);
		form.setValue("question_type", assignment.data.question_type);
		form.setValue("question_count", assignment.data.question_count);
	}, [assignment.data]);

	const onSubmit = (data) => {
		console.log("Updating Assignment...");
		console.dir(data);
		mutation.mutate(data, {
			onSuccess: (data, values) => {
				queryClient.invalidateQueries(["admin-oral-assignment"]);
				history.push(`/oral/assignments/${id}`);
			},
			onError: (error, variables, context) => {
				console.log(error.response, variables, context);
			},
		});
	};

	const buttonGroupClasses = classNames("flex items-center float-right my-6", {
		invisible: !form.formState.isDirty,
	});

	const questionTypes = [
		{ value: 1, label: "Assign All Questions" },
		{ value: 2, label: "Random Sample of Questions" },
	];

	return (
		<React.Fragment>
			<div className="flex flex-row items-center">
				<h1 className="my-4 text-2xl font-bold flex-grow">
					Edit - {assignment.data.title}
				</h1>
				<span className="flex-initial mx-2">
					<CurrentState state={assignment.data.state} />
				</span>
			</div>
			<hr className="border-1 border-solid border-black my-4" />
			<div className="flex mt-8 bg-blue-200 border border-blue-400 px-4 w-full">
				<FormProvider {...form}>
					<form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
						<div className="my-6">
							<Input
								label="Assignment Title"
								name="title"
								value={assignment.data.title}
							/>
						</div>
						<div className="my-6">
							<DateInput
								label="Publish Date"
								name="publish_date"
								value={assignment.data.publish_date}
							/>
						</div>
						<div className="my-6">
							<DateInput
								label="Due Date"
								name="due_date"
								value={assignment.data.due_date}
							/>
						</div>
						<div className="my-6">
							<div className="w-full">
								<label
									htmlFor="question_type"
									className="my-6 block text-sm/6 font-semibold text-gray-900"
								>
									Question Assignment
								</label>
								<Controller
									control={form.control}
									name="question_type"
									render={({ field }) => (
										<Select
											inputRef={field.ref}
											options={questionTypes}
											value={questionTypes.find((c) => c.value === field.value)}
											onChange={(value) => field.onChange(value.value)}
										/>
									)}
								/>
							</div>
						</div>
						{selectedQuestionType === 2 && (
							<div className="my-6">
								<Input
									label="Number of Questions to Assign"
									name="question_count"
									value={assignment.data.question_count}
									type="number"
								/>
							</div>
						)}
						<div className="my-6 flex items-center">
							<label
								htmlFor={`id_sends_email`}
								className="w-1/5 block text-sm/6 font-semibold text-gray-900"
							>
								Send email?
							</label>
							<div className="">
								<input
									type="checkbox"
									className="w-6 h-6"
									name="sends_email"
									id="id_sends_email"
									{...form.register("sends_email")}
								/>
							</div>
						</div>
						<div className="my-6 flex items-center">
							<label
								htmlFor={`id_sends_email`}
								className="w-1/5 block text-sm/6 font-semibold text-gray-900"
							>
								Require file submission?
							</label>
							<div className="">
								<input
									type="checkbox"
									className="w-6 h-6"
									name="require_submission"
									id="id_require_submission"
									{...form.register("require_submission")}
								/>
							</div>
						</div>
						<div className="my-6 flex items-center">
							<label
								htmlFor={`id_allow_late_work`}
								className="w-1/5 block text-sm/6 font-semibold text-gray-900"
							>
								Allow late work?
							</label>
							<div className="">
								<input
									type="checkbox"
									className="w-6 h-6"
									name="allow_late_work"
									id="id_allow_late_work"
									{...form.register("allow_late_work")}
								/>
							</div>
						</div>
						<div className="my-6">
							<DateInput
								label="Late Due Date"
								name="late_due_date"
								value={assignment.data.late_due_date}
								invisible={!allowLateWork}
							/>
						</div>
						{assignment.data.state === "active" && (
							<div className="my-6">
								<p>
									<strong>WARNING:</strong> Editing your Oral assignment while
									active with students may lead to confusion.
								</p>
							</div>
						)}
						<div className="my-6">
							<div className={buttonGroupClasses}>
								<button
									className="mr-8"
									onClick={() => history.push(`/oral/assignments/${id}`)}
								>
									Cancel
								</button>
								<button className={buttonClasses} type="submit">
									Save Changes
								</button>
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
			<Questions assignment={assignment} />
		</React.Fragment>
	);
};

export default OralEdit;
