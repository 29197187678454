import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';
import X from '../../../components/x/x';
import Checkmark from '../../../assets/icons/check-1.svg';
import Submission from '../../../assets/icons/office-file-text.svg';
import { useUser } from '../../../queries/user';
import {
  useOralAssignment, useOralSubmissionsForAssignment
} from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import CurrentState from './current-state';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

const OralSubmissionRow = ({ submission }) => {
  const { id } = useParams();
  const history = useHistory();
  let icon = <X className="w-12 h-12 fill-red-500 m-4" />

  if (submission.is_complete) {
    icon = <Checkmark className="w-4 h-4 text-green-500 m-2" />
  }

  const handleClick = () => {
    history.push(`/oral/assignments/${id}/submission/detail/${submission.section_member_id}`);
  }

  const rowClasses = classNames("flex flex-inline items-center cursor-pointer hover:bg-gray-200 border border-gray-500 p-6 w-full my-4 bg-white shadow-sm", {
  })

  return (
    <div
      className={rowClasses}
      onClick={handleClick}
    >
      <div className="w/1-5">
      </div>
      <div className="w/2-5 flex-grow">
        <p className="text-xl font-bold mb-2">
          {submission.first_name} {submission.last_name}
        </p>
        <p>{submission.email}</p>
      </div>
      <div className="w/1-5 mx-auto">
        {icon}
      </div>
      <div className="w/1-5">
        {submission.has_submission_file ?
          <Submission className="w-6 h-6 text-blue-500 m-4" /> : ''
        }
      </div>
    </div>
  )
}

const OralSubmissions = (props) => {
  const { id } = useParams();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const submissions = useOralSubmissionsForAssignment(id);

  if (user.isLoading || assignment.isLoading || submissions.isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Submissions - {assignment.data.title}</h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="mt-6">
        {submissions.data.map((submission) => (
          <OralSubmissionRow
            key={`submission-${submission.section_member_id}`}
            submission={submission}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default OralSubmissions;
