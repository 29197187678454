import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortDate } from '../../common/utils';
import { stateText } from '../../common/state';
import Close from '../../assets/icons/close.svg';
import Checkmark from '../../assets/icons/check-1.svg';
import Rating from '../../assets/icons/rating-star-ribbon.svg';
import Submission from '../../assets/icons/office-file-text.svg';
import Speaker from '../../assets/icons/speaker.svg';

import styles from './todo-list-item.module.scss';


const TodoListItem = (props) => {
  const {
    assignmentID, type, reviewID,
    reviewFeedbackID, oralID, subtitle, assignmentStatus, dueDate, isActive,
    isComplete, errorText, assignmentType, submissionAuthor, submissionModified,
  } = props;
  const [showHover, setShowHover] = useState(false);

  let url = `/assignments/${assignmentID}/submissions/create`;
  let todoTitle = 'Create a Submission';
  let icon = <Submission className={styles.svg} />;
  let title = stateText("submission", false, true);
  let isTeamAssignment = assignmentType === 'team';
  let teamSubmissionExtraLine = '';
  let modalState = true;

  if (isTeamAssignment) {
    todoTitle = 'Create a Team Submission';
    if (type === 'submission') {
      teamSubmissionExtraLine = `${submissionAuthor} submitted at ${shortDate(submissionModified)}`;
    }
  }


  if (type === 'late_submission') {
    todoTitle = 'Create Late Submission';
  }

  // This is the TODO item where they trigger the "submit" portion of their flow
  if (type === 'late_submit') {
    todoTitle = 'Submit your Late Submission';
    url = `/assignments/${assignmentID}/late-submit`;
    modalState = false;
  }

  if (type === 'review' || type === 'late_review') {
    url = `/submissions/${reviewID}/reviews/create`;
    todoTitle = 'Review a Submission';
    icon = <Rating className={styles.svg} />;
    title = stateText("review", false, true);
  }

  if (type === 'feedback') {
    url = `/submissions/${reviewFeedbackID}/review_feedbacks/create`;
    todoTitle = 'Score a Review';
    icon = <Rating className={styles.svg} />;
    title = stateText("review_feedback", false, true);
  }

  if (type === 'oral') {
    todoTitle = 'Create Oral Assignment';
    url = `/my-oral/${oralID}`;
    icon = <Speaker className={styles.svg} />;
    title = stateText("oral", false, true);
    modalState = false;
  }

  if (type === 'error') {
    todoTitle = `ERROR: ${errorText}`
  }

  // Determine if completed or not
  let containerStyles = styles.container
  if (isComplete) {
    icon = <Checkmark className={styles.svg} />;
    containerStyles = `${styles.container} ${styles.complete}`;
  }

  // Handle active/inactive
  if (!isActive) {
    containerStyles = styles.inactive_container;
  }

  // Use dueDate if given
  let other = subtitle;

  // If we have a dueDate and it's in the past disable the link
  let linkDisabled = !isActive;
  const now = moment().toDate();
  const due = moment(dueDate).toDate();
  if (due < now) {
    linkDisabled = true;
    containerStyles = styles.inactive_container;
  }

  if (dueDate && linkDisabled) {
    other = `Was due ${shortDate(dueDate)}`;
  } else {
    other = `Due ${shortDate(dueDate)}`;
  }

  if (showHover && linkDisabled) {
    todoTitle = "This stage has ended";
  }

  return (
    <div
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}>
      <Link
        className={containerStyles}
        to={{ pathname: url, state: { modal: modalState } }}
        disabled={linkDisabled}
      >
        <div className={styles.icon}>
          {icon}
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{todoTitle}</div>
          <div className={styles.subtitle}>{title} - {other}</div>
          {(isComplete && isTeamAssignment) && (<div className={styles.subtitle}>{teamSubmissionExtraLine}</div>)}
        </div>
      </Link></div>
  );
};

TodoListItem.propTypes = {
  type: PropTypes.string.isRequired,
  assignmentID: PropTypes.string.isRequired,
  reviewID: PropTypes.string,
  reviewFeedbackID: PropTypes.string,
  isComplete: PropTypes.bool,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TodoListItem.defaultProps = {
  reviewID: '',
  reviewFeedbackID: '',
  isComplete: false,
  isActive: false,
  title: '',
  subtitle: '',
};

export default TodoListItem;
