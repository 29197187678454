import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import Checkmark from "../../../assets/icons/check-1.svg";
import { useUser } from "../../../queries/user";
import {
  useOralAssignment,
  useOralSubmissionDetail,
  useOralPracticeDetail,
  useOralSubmissionFileDetail,
} from "../../../queries/admin-queries";
import Loading from "../../../components/loading/loading";
import CurrentState from "./current-state";

const buttonClasses = classNames(
  "rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1",
);
const greenButtonClasses = classNames(
  "rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1",
);

const VideoButton = (props) => {
  const { id, sectionMemberID } = useParams();
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    const path = props.isEmbedded
      ? `/assignments/${id}/submission/video/${sectionMemberID}/${props.submissionID}`
      : `/oral/assignments/${id}/submission/video/${sectionMemberID}/${props.submissionID}`;
    history.push(path);
  };

  return (
    <div
      className="flex flex-inline bg-blue-500 hover:bg-blue-700 rounded p-2 text-white items-center w-full shadow-sm"
      onClick={handleClick}
    >
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-12 h-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
      <span className="ml-6">Video</span>
    </div>
  );
};

const TranscriptButton = (props) => {
  const { id, sectionMemberID } = useParams();
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    history.push(
      `/oral/assignments/${id}/submission/transcript/${sectionMemberID}/${props.submissionID}`,
    );
  };
  return (
    <div
      className="flex flex-inline bg-blue-500 hover:bg-blue-700 rounded p-2 text-white items-center w-full shadow-sm"
      onClick={handleClick}
    >
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-12 h-12"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
        />
      </svg>
      <span className="ml-6">Transcript</span>
    </div>
  );
};

const Score = ({ score }) => {
  return (
    <div className="w-full mt-6">
      <div className="w-full grid grid-cols-5 text-xl">
        <p className="col-span-3">
          <strong className="mr-1">Criteria:</strong>
          {score.criteria.criteria}
        </p>
        <p>
          <strong className="mr-1">Score:</strong>
          {score.score}
        </p>
        <p>
          <strong className="mr-1">Total Points:</strong>
          {score.criteria.points}
        </p>
      </div>
      <div className="w-full mt-2">
        <p>
          <strong className="mr-1">Justification:</strong>
          {score.justification}
        </p>
      </div>
    </div>
  );
};

const Scores = ({ scores }) => {
  console.dir(scores);
  return (
    <div className="mt-6">
      {scores.map((score, index) => (
        <Score key={`score-${index}`} score={score} />
      ))}
    </div>
  );
};

const OralSubmissionRow = ({ submission, assignment, index }) => {
  let icon = null;

  if (submission.is_late) {
    icon = <Checkmark className="w-4 h-4 text-red-500 m-2" />;
  }

  const rowClasses = classNames(
    "border border-gray-600 p-6 w-full my-4 bg-white shadow-sm",
    {
      "bg-green-200": submission.is_complete,
      "bg-green-100": submission.is_late,
    },
  );

  return (
    <div className={rowClasses}>
      <div className="flex flex-inline">
        <div className="w-4/5">
          <h2 className="text-2xl font-semibold">
            Question #{index + 1} - {submission.question.question_type.name}
          </h2>
          <p className="my-4">{submission.question.question_text}</p>
          {submission.is_late && (
            <p>
              <strong>Late Submission:</strong>{" "}
              {moment(submission.modified).local().format("YYYY-MM-DD HH:mm")}
            </p>
          )}
        </div>
        <div className="w-1/5 flex flex-col items-center text-center space-y-4">
          {submission.video_file && (
            <VideoButton
              submissionID={submission.id}
              isEmbedded={!assignment.is_standalone}
            />
          )}
          {submission.transcript && (
            <TranscriptButton submissionID={submission.id} />
          )}
        </div>
      </div>
      {submission.is_graded && <Scores scores={submission.scores} />}
    </div>
  );
};

const OralPractice = ({ id, sectionMemberID }) => {
  const practice = useOralPracticeDetail(id, sectionMemberID);

  if (practice.isLoading) {
    return <Loading />;
  }

  if (!practice.data.video_url) {
    return null;
  }

  return (
    <div className="w-full border border-gray-700 bg-blue-100 p-6">
      Practice Recording Completed at{" "}
      {moment(practice.data.modified).local().format("YYYY-MM-DD HH:mm")}
    </div>
  );
};

const OralFile = ({ id, sectionMemberID }) => {
  const file = useOralSubmissionFileDetail(id, sectionMemberID);

  if (file.isLoading) {
    return <Loading />;
  }

  if (!file.data.file_url) {
    return null;
  }

  return (
    <div className="w-full border border-gray-700 bg-blue-100 p-6">
      Student{" "}
      <a
        href={file.data.file_url}
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer underline text-blue-500 hover:text-blue-700"
      >
        File Submission
      </a>{" "}
      uploaded at{" "}
      {moment(file.data.modified).local().format("YYYY-MM-DD HH:mm")}
    </div>
  );
};

const OralSubmissionDetail = () => {
  const { id, sectionMemberID } = useParams();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const submissions = useOralSubmissionDetail(id, sectionMemberID);

  if (user.isLoading || assignment.isLoading || submissions.isLoading) {
    return <Loading />;
  }

  const firstName = submissions.data[0].author_first_name;
  const lastName = submissions.data[0].author_last_name;
  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">
          Submission Detail - {firstName} {lastName}
        </h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="mt-6">
        <OralPractice id={id} sectionMemberID={sectionMemberID} />
      </div>
      <div className="mt-6">
        <OralFile id={id} sectionMemberID={sectionMemberID} />
      </div>
      <div className="mt-6">
        {submissions.data.map((submission, index) => (
          <OralSubmissionRow
            key={`submission-${submission.id}`}
            submission={submission}
            assignment={assignment.data}
            index={index}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default OralSubmissionDetail;
