import React, { useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useUser } from '../../../queries/user';
import { useCourse, getCourseEmptySections } from '../../../queries/admin-queries';
import Modal from '../../../components/modal/modal';
import Icon from '../../../components/help';
import styles from './course-detail.module.scss';

const DemoModal = (props) => {
  const { customCloseHandler } = props;

  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white w-full bg-green-500');

  return (
    <Modal
      title="Welcome to the Peer Exchange Demonstration: Instructor Preview"
      customCloseHandler={customCloseHandler}
      fullWidth={true}
    >
      <p className="my-4">
        Follow the steps on this page to see our demo assignment and to create
        your own.  You will find <Icon /> icons for quick definitions and more detailed
        instructions.  Your edits will be saved for 7 days, after which the
        preview will return to its original state.
      </p>

      <button onClick={() => customCloseHandler()} className={buttonClasses}>Close</button>
    </Modal>

  );
};

const CourseDetailOverview = (props) => {
  const { id } = useParams();
  const course = useCourse(id);
  const user = useUser();
  const emptySections = useQuery(['admin-course-empty', id], () => getCourseEmptySections(id));
  const [showDemoModal, setShowDemoModal] = useState(null);


  if (course.isLoading || user.isLoading || emptySections.isLoading) {
    return <div>Loading...</div>
  }

  let showSectionWarning = !course.data.is_lti && !course.data.has_sections;
  let isDemo = course.data.is_demo;
  let showEmptyWarning = emptySections.data.length > 0;
  let isHBP = user.data.is_hbp

  return (
    <React.Fragment>
      <div className={styles.actions}>
        <div className="container">
          <Link
            className={styles.smallButton}
            to={{ pathname: `/courses/${id}/edit`, state: { modal: true } }}
          >
            Edit Course
          </Link>
        </div>
      </div>
      <div className="container">
        <div className={styles.header}>
          <h3>
            {course.data.name}
          </h3>
        </div>
        <div className="mt-8 bg-blue-100 border border-blue-500 p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">To Create a New Assignment being with SECTIONS</h2>
          <p className="my-2">
            <strong>STEP 1:</strong> Click the SECTIONS tab in the navigation bar above
          </p>
          <p className="my-2">
            <strong>STEP 2:</strong> Select the SECTION for the new Assignment
          </p>
          <p className="my-2">
            <strong>STEP 3:</strong> Select the type of assignmetn and follow instructions
          </p>
          {isHBP && (
            <p className="my-2">
              (Every coursepack has one Section titled <em>Harvard Business Publishing</em>. Students do not see the Section name.)
            </p>
          )}
        </div>
        {showSectionWarning &&
          <>
            <div className="bg-gray-200 sm:rounded-lg my-6">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg font-bold leading-6 text-gray-900">Missing Sections</h3>
                <div className="mt-2 text-sm text-gray-700">
                  <p className="my-2">
                    You currently do not have any Sections defined for this course.  You must have at least one Section
                    to be able to create an Assignment.
                  </p>
                  <p className="my-2">This can be any name you choose.  Students never see the section name.</p>
                </div>
                <div className="mt-5">
                  <Link
                    className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 hover:bg-blue-700 px-4 py-2 font-medium text-gray-100 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    to={{ pathname: `/sections/create/${id}`, state: { modal: true } }}
                  >
                    Create A Section
                  </Link>
                </div>
              </div>
            </div>
          </>
        }
        {showEmptyWarning &&
          <>
            <div className="bg-gray-200 sm:rounded-lg my-6">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg font-bold leading-6 text-gray-900">Missing Assignments - Assignments Required to Begin</h3>
                <div className="mt-2 text-sm text-gray-700">
                  <p>
                    You currently do not have any Assignments defined for these sections.  You must have at least one Assignment
                    in each Section for the platform to function.</p>
                  <p className="my-2"> Click on each section below to create an Assignment.</p>
                  <ul className="my-2 list-disc">
                    {emptySections.data.map((item, index) => (
                      <li className="ml-8" key={`section-${index}`}>
                        <Link className="text-blue-500 hover:text-blue-700" to={{ pathname: `/sections/${item.id}` }}>
                          Section Title: {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        }
        {showDemoModal && <DemoModal customCloseHandler={() => setShowDemoModal(false)} />}
        <div className={styles.content}>
          <p>
            {course.data.description}
          </p>
        </div>
        <h3 className="text-lg font-bold my-6">We suggest that instructors watch these short videos that explain the student experience. (The videos combined are less than 3 minutes).</h3>
        <div className={styles.content}>
          <p className="my-2 text-blue-500 hover:text-blue-700 underline">
            <a href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage1.mp4" target="_new">Stage 1: Submission</a>
          </p>
          <p className="my-2 text-blue-500 hover:text-blue-700 underline">
            <a href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage2.mp4" target="_new">Stage 2: Review</a>
          </p>
          <p className="my-2 text-blue-500 hover:text-blue-700 underline">
            <a href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage3.mp4" target="_new">Stage 3: Score Reviews</a>
          </p>
        </div>
        {
          course.data.is_lti &&
          <>
            <div className={styles.lti}>
              <table className="table-fixed">
                <thead>
                  <tr>
                    <th colSpan="2">LTI Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>LTI Course ID</th>
                    <td>{course.data.lms_id}</td>
                  </tr>
                  <tr>
                    <th>LTI Course Name</th>
                    <td>{course.data.lms_course_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
      </div >
    </React.Fragment >
  );
};


export default CourseDetailOverview;
