import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useMutation, useQueryClient } from "react-query";
import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { useUser } from "../../../queries/user";
import {
  useOralAssignment,
  startOralAssignment,
  reportProblem,
  uploadFile,
} from "../../../queries/student_assignments";
import Navigation from "../../../components/navigation/navigation";
import Button from "../../../components/button/button";
import Loading from "../../../components/loading/loading";
import { useRollbar } from "@rollbar/react";
import uap from "ua-parser-js";

const getExtension = (str) => {
  str.slice(str.lastIndexOf("."));
};

const FileSubmission = ({ assignmentID, memberID, required, needed }) => {
  console.log("File Submission", assignmentID, required, needed);
  const queryClient = useQueryClient();
  const rollbar = useRollbar();

  // Nothing to do if we don't allow files
  if (!required) {
    return null;
  }

  if (!needed) {
    return (
      <div className="my-6 bg-green-100 border border-green-400 p-6">
        You have submitted the required file.
      </div>
    );
  }

  const form = useForm();
  const mutation = useMutation(uploadFile, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["student-oral-assignment"]);
    },
    onSuccess: (data, variables, context) => {
      console.log("File uploaded successfully", data);
    },
    onError: (data, error, variables, context) => {
      rollbar.error(error, context);
    },
  });

  const onSubmit = (data) => {
    console.log("Submitting file", data);

    const extension = getExtension(data.file[0].name);
    const filename = `oral-file-${assignmentID}-${memberID}.${extension}`;

    mutation.mutate({
      id: assignmentID,
      fileBlob: data.file[0],
      filename: filename,
    });
  };

  return (
    <div className="my-6 bg-blue-100 border border-blue-400 p-6">
      <h2 className="text-xl font-bold">File Submission Required:</h2>
      <p className="my-4">
        Your instructor has assigned an uploaded file Submission in addition to
        this Oral Assignment.
      </p>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <input
          className="w-full px-4 py-2 text-lg text-gray-700 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500 my-4"
          type="file"
          {...form.register("file")}
        />
        <Button type="submit" disabled={!form.formState.isValid}>
          Submit File
        </Button>
      </form>
    </div>
  );
};

const Question = ({ question, index, assignmentID }) => {
  const mutation = useMutation("oral-submission-start", () =>
    startOralAssignment(assignmentID, question.id),
  );
  const rollbar = useRollbar();
  const history = useHistory();

  let unstarted = true;

  if (question.is_complete || question.is_running || question.is_over_time) {
    unstarted = false;
  }

  const questionClasses = classNames("border p-6 my-6", {
    "hover:bg-gray-400": unstarted,
    "bg-gray-200": unstarted,
    "border-gray-900": unstarted,
    "bg-green-200": question.is_complete,
    "border-green-500": question.is_complete,
    "bg-yellow-200": question.is_running,
    "border-yellow-500": question.is_running,
    "bg-red-200": question.is_over_time,
    "border-red-500": question.is_over_time,
  });

  const handleClick = (event) => {
    event.preventDefault();

    if (question.is_complete) {
      console.log("Clicked, but already completed so doing nothing.");
      return;
    }

    console.log(`Question ${index + 1} clicked.`);
    mutation.mutateAsync(
      {},
      {
        onSuccess: () => {
          console.log("oral submission started successfully");
          history.push(
            `/my-oral-assignments/${assignmentID}/${question.id}/started`,
          );
          rollbar.info(
            `Started Question #${question.id} in oral assignment ${assignmentID}`,
          );
        },
        onError: (error, context) => {
          console.error("error starting oral submission", error);
          rollbar.error(error, context);
        },
      },
    );
  };

  const questionNumber = index + 1;
  let mainText = <p>Begin Question #{questionNumber}</p>;

  if (question.is_running) {
    mainText = <p>Continue Question #{questionNumber}. TIMER IS RUNNING!</p>;
  }

  if (question.is_over_time) {
    mainText = <p>Question #{questionNumber} was not completed in time</p>;
  }

  if (question.is_complete) {
    mainText = <p>Question #{questionNumber} completed!</p>;
  }
  return (
    <div
      onClick={handleClick}
      className={questionClasses}
      key={`question-${index}`}
    >
      {mainText}
    </div>
  );
};

const QuestionList = ({ questions, assignmentID }) => {
  const count = questions.length;
  let bannerText = `You will be asked to answer ${count} separate questions.`;
  let completedText = null;

  if (count === 1) {
    bannerText = "You will be asked to answer one question.";
  }

  if (questions.every((question) => question.is_complete)) {
    completedText = (
      <h3 className="w-full text-center text-3xl text-green-500 font-bold">
        All questions have been completed!
      </h3>
    );
  }

  // Let them know there are no questions
  if (count === 0) {
    console.log("here");
    completedText = (
      <h3 className="w-full text-center text-3xl text-yellow-500 font-bold">
        There are no questions for you to answer at this time.
      </h3>
    );
  }
  console.log("Count", count);

  return (
    <>
      <hr className="border-1 border-solid border-black my-12" />
      <div className="mb-32">
        {!completedText && (
          <>
            <h2 className="text-2xl font-semibold">{bannerText}</h2>
            <p className="my-4 text-xl mb-8">
              The 5 minute timer starts <strong>Immediately</strong> when you
              click 'Begin Question' below. Be ready to read the question and
              record.
            </p>
          </>
        )}

        {completedText}

        {!completedText &&
          questions.map((question, index) => (
            <Question
              key={`question-list-${index}`}
              question={question}
              index={index}
              assignmentID={assignmentID}
            />
          ))}
      </div>
    </>
  );
};
const TextInput = ({
  label,
  name,
  placeholder = undefined,
  value = undefined,
  autoFocus = false,
  rows = 3,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[name]?.message?.toString();

  useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, [value, setValue, name]);

  return (
    <div className="w-full">
      <label
        htmlFor={`id_${name}`}
        className="block text-sm/6 font-semibold text-gray-900"
      >
        {label}
      </label>
      <div className="mt-6 w-full">
        <textarea
          id={`id_${name}`}
          name={name}
          placeholder={placeholder}
          className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-red-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 sm:pr-9 sm:text-sm/6"
          autoFocus={autoFocus}
          rows={rows}
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

const ReportProblemButton = () => {
  const { id } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const form = useForm();

  const mutation = useMutation("report-problem", (data) =>
    reportProblem(id, data),
  );

  if (submitted) {
    return (
      <div className="w-full bg-red-200 border border-red-500 p-6">
        <strong>Successful submission of your problem or file</strong>
      </div>
    );
  }

  if (!showForm) {
    return (
      <div className="mb-20 flex justify-center">
        <button
          type="button"
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-4 px-8 rounded-md flex flex-inline items-center"
          onClick={() => setShowForm(true)}
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-2"
            aria-label="Danger Icon"
            role="img"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
            />
          </svg>
          <span>
            Another chance to submit a video if you encountered a problem
          </span>
        </button>
      </div>
    );
  }

  const onSubmit = (data) => {
    // Show that we're submitting the form
    setSubmitting(true);

    // reshape the data
    const uaString = window.navigator.userAgent;
    const ua = uap(uaString);
    data.data = ua;
    console.log("Problem report submitted:", data);
    const formData = new FormData();
    formData.append("problem", data.problem);
    formData.append("data", JSON.stringify(ua));
    if (data.file && data.file[0]) {
      formData.append("file", data.file[0]);
    }

    // actually submit
    mutation.mutate(data, {
      onSuccess: () => {
        console.log("Problem report submitted successfully");
        setSubmitted(true);
        setShowForm(false);
      },
      onError: (error, context) => {
        console.error("error submitting problem report", error);
        rollbar.error(error, context);
      },
    });
  };

  if (submitting) {
    return (
      <div className="w-full bg-red-200 border border-red-500 p-6">
        <div className="flex justify-center m-6">
          <p className="my-4">
            <strong>Submitting your problem and file...</strong>
          </p>
          <p className="my-4">
            This may take several minutes depending on how large your video is.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full bg-red-200 border border-red-500 p-6">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-4">
            <p>
              If you had trouble recording your video you can record one on your
              device and upload the file here.
            </p>
            <p className="my-4">
              <strong>NOTE:</strong> Uploaded video files must be{" "}
              <strong>5 minutes or less</strong>. Larger files may generate an
              error and not be able to be submitted.
            </p>
            <p className="my-4">
              Please use Chrome or Firefox to record (avoid Safari). Lower video
              quality is fine - we only need to hear you clearly.
            </p>
            <ul className="my-4 list-disc ml-8">
              <li>
                On phones: Open Camera Settings &arr; select 720p or lower
              </li>
              <li>
                On Windows/Mac: Open Camera Settings &arr; select 720p or lower
              </li>
            </ul>
          </div>
          <TextInput
            label="Problem Description"
            name="problem"
            placeholder="Enter a detailed description of the problem you experienced."
            rows={5}
          />
          <div className="w-full my-4">
            <label
              htmlFor={"id_file"}
              className="block text-sm/6 font-semibold text-gray-900"
            >
              Video File (optional)
            </label>
            <div className="mt-6 w-full">
              <input type="file" id="id_file" {...form.register("file")} />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button type="submit" className="">
              Submit Video &amp; Report
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const OralDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [hasPermission, setHasPermission] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const user = useUser();
  const assignment = useOralAssignment(id);

  const handleGetPermission = async () => {
    await navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => setHasPermission(true))
      .catch((err) => {
        console.error(err);
        location.reload();
      });
  };

  if (assignment.isLoading || user.isLoading) {
    return <Loading />;
  }

  navigator.permissions
    .query({ name: "camera" })
    .then((result) => {
      if (result.state === "granted") {
        setHasPermission(true);
      }

      if (result.state === "denied") {
        setPermissionDenied(true);
      }
    })
    .catch(() => {
      // Likely firefox which doesn't support permissions API the same way
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {
          setHasPermission(true);
        })
        .catch(() => {
          setHasPermission(false);
        });
    });

  return (
    <div>
      <Navigation
        history={history}
        title={assignment.data.title}
        backURL={"/dashboard"}
        hasBackButton
      />
      <div className="p-4">
        <div className="my-2">
          <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
            Oral Assignment
          </h2>
        </div>
        <div className="my-4 border border-1 border-grey-400 bg-gray-100 p-4">
          <h2 className="text-2xl bold p-2">
            We Want to Hear <em>Your</em> Voice!
          </h2>
          <p className="my-4">
            This is your chance to showcase your understanding of the course
            materials. This is a quick, personal way to share your knowledge and
            perspectives.
          </p>

          <h3 className="text-2xl font-bold my-4">Here's Why It Matters:</h3>

          <ul className="list-disc ml-8">
            <li className="my-4">
              <strong>It's About You.</strong> Your instructors don't always get
              to hear from every student as much as they would like. This is
              your opportunity to share your understanding of the material in
              your own words.
            </li>
            <li className="my-4">
              <strong>Show What You Know.</strong>
              Your take on the material matters.
            </li>
            <li className="my-4">
              <strong>No Public Speaking Stress.</strong> You're not being
              evaluated on how polished you sound. This is all about your ideas,
              insights, and knowledge. We care about what you're saying - not
              how you say it.
            </li>
            <li className="my-4">
              <strong>Practice Real-Life Skills.</strong> Speaking clearly and
              confidently is a skill for life, from the increasing use of video
              interviews and pitches, to voice and video collaborations. This
              helps you sharpen those skills.
            </li>
          </ul>

          <h3 className="text-2xl font-bold my-4">Here's How It Works:</h3>

          <p className="my-4">What to expect:</p>

          <ul className="list-disc ml-8">
            <li className="my-4">
              You will give a short oral response to a set of questions provided
              by your teacher.
            </li>
            <li className="my-4">
              Your response will be recorded for them to review.
            </li>
            <li className="my-4">You will have 5 minutes for each question.</li>
          </ul>

          <p className="my-4">Recording Your Response:</p>

          <ul className="list-disc ml-8">
            <li className="my-4">
              Complete the Practice Recording: Test your equipment to ensure
              good quality playback. Your instructor will be aware that you
              completed this check, but will not have access to recording
              itself. Your instructor will have access to your answers to the
              assignment questions - explained below.
            </li>
            <li className="my-4">Now you're ready to begin.</li>
            <li className="my-4">Click "Begin Oral Assignment: Question #1"</li>
            <li className="my-4">
              <strong>
                IMPORTANT: This immediately starts the countdown timer
              </strong>
              &nbsp;for your 5-minute question period.
            </li>
            <li className="my-4">Read the question(s)</li>
            <li className="my-4">Collect your thoughts</li>
            <li className="my-4">
              Click "Start Recording" when ready to speak
            </li>
            <li className="my-4">
              Speak clearly and not too quickly for better-quality recording.
            </li>
            <li className="my-4">Click "Stop and Submit" when finished</li>
            <li className="my-4">
              After completing Question #1, repeat the process for any
              subsequent questions.
            </li>
          </ul>
          <p className="my-4">Important Notes:</p>

          <ul className="list-disc ml-8">
            <li className="my-4">
              Each question totals 5 minutes and{" "}
              <strong>can be recorded only once.</strong>
            </li>
            <li className="my-4">
              You immediately start the 5-minute countdown timer when you click
              "Begin Oral Assignment: Question #.."
            </li>
            <li className="my-4">
              But your audio and video will NOT be captured until you click
              "Start Recording."
            </li>
            <li className="my-4">
              Even though you'll see yourself on camera, nothing is being
              recorded until you click “Start Recording.”
            </li>
            <li className="my-4">
              The reason: You need time to read the question(s) and collect your
              thoughts before speaking.
            </li>
            <li className="my-4">
              If you forget to click on "Stop and Submit" your recording will be
              saved and automatically stop at 5 minutes.
            </li>
          </ul>
        </div>

        {!hasPermission && !permissionDenied && (
          <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
            You must grant permission to use your camera and microphone in your
            browser to record your oral assignment. You should see a popup
            message asking for your permission.
          </div>
        )}

        {!hasPermission && permissionDenied && (
          <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
            <h4 className="font-bold text-2xl mb-4">
              Camera Permission Denied
            </h4>
            <p className="my-2">
              You have denied permission to use your camera and microphone. This
              must be enabled to complete your Oral Assignment. You need to
              either enable these permissions manually or reset them so we can
              ask for your permission again.
            </p>

            <h5 className="font-bold text-xl my-4">For Chrome</h5>
            <ol className="list-decimal ml-8">
              <li>
                Click 'View Site Information' or the Lock icon to the left of
                the address bar
              </li>
              <li>Click 'Site settings</li>
              <li>
                Change Permission Settings or click 'Reset Permissions' button
              </li>
            </ol>
            <h5 className="font-bold text-xl my-4">For Firefox</h5>
            <ol className="list-decimal ml-8">
              <li>Open the menu and go to Preferences or Options</li>
              <li>Scroll down to Privacy & Security</li>
              <li>Go to Cookies & Site Data</li>
              <li>Select Clear Data</li>
            </ol>
            <h5 className="font-bold text-xl my-4">For Safari</h5>
            <ol className="list-decimal ml-8">
              <li>
                Click on the Safari app menu in the upper left corner of the
                screen
              </li>
              <li>Click on Settings for this website</li>
            </ol>
          </div>
        )}

        {hasPermission && (
          <>
            <p className="mt-12 my-6 text-center">
              Please ensure you have read the instructions above before
              beginning your Oral Assignment.
            </p>

            <div className="my-8 w-full">
              <button
                type="button"
                className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-4 px-8 rounded"
                onClick={() =>
                  history.push(`/my-oral-assignments/${id}/practice`)
                }
              >
                Practice Recording
              </button>
            </div>
          </>
        )}

        {permissionDenied && (
          <p className="mt-12 my-6 text-center">
            Follow the instructions above in the red box to enable your camera
            and microphone.
          </p>
        )}

        <div className="my-4 text-center">
          {!hasPermission && !permissionDenied && (
            <Button onClick={handleGetPermission}>
              Enable Camera and Microphone
            </Button>
          )}
        </div>

        {hasPermission && (
          <FileSubmission
            assignmentID={id}
            required={assignment.data.require_submission}
            needed={assignment.data.needs_file_submission}
          />
        )}

        {hasPermission && (
          <QuestionList
            assignmentID={id}
            questions={assignment.data.questions}
          />
        )}

        <ReportProblemButton />
      </div>
    </div>
  );
};

export default OralDetail;
