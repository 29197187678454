import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { useUser } from "../../../queries/user";
import { useEmbeddedOralSubmissionDetail } from "../../../queries/admin-queries";
import Loading from "../../../components/loading/loading";

const buttonClasses = classNames(
	"rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1",
);

const AssignmentDetailOralVideo = () => {
	const { id, sectionMemberID, submissionID } = useParams();
	const history = useHistory();
	const user = useUser();
	const submissions = useEmbeddedOralSubmissionDetail(id, sectionMemberID);

	if (user.isLoading || submissions.isLoading) {
		return <Loading />;
	}

	const sub = submissions.data.find((s) => s.id == submissionID);

	return (
		<React.Fragment>
			<div className="flex flex-row items-center">
				<h1 className="my-4 text-2xl font-bold flex-grow">
					Submission Video - {sub.author_first_name} {sub.author_last_name}
				</h1>
			</div>
			<hr className="border-1 border-solid border-black my-4" />
			<div className="mt-6">
				<div className="w-full flex items-center justify-center">
					<video src={sub.video_file} width="800" height="600" controls />
				</div>
			</div>
			<div className="mt-12">
				<button
					type="button"
					className={buttonClasses}
					onClick={() =>
						history.push(
							`/assignments/${id}/oral/submission/detail/${sectionMemberID}`,
						)
					}
				>
					Return to Submission Details
				</button>
			</div>
		</React.Fragment>
	);
};

export default AssignmentDetailOralVideo;
