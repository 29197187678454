import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../../queries/user';
import { useOralAssignment, useOralQuestionSummary } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import CurrentState from './current-state';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');


const ButtonLink = (props) => (
  <span className={buttonClasses}>
    <Link to={props.url}>{props.children}</Link>
  </span>
);

const GreenButtonLink = (props) => (
  <span className={greenButtonClasses}>
    <Link to={props.url}>{props.children}</Link>
  </span>
);


const QuestionSummary = ({ summary, index }) => {

  return (
    <div className="border border-gray-500 p-4 my-6 mr-6 bg-blue-100">
      <div className="flex flex-inline">
        <div className="w-4/5 flex-grow">
          <h2 className="mb-4 text-xl font-bold flex-grow">Question #{index + 1} {summary.question_type}</h2>
          <p className="my-4 text-base">{summary.question_text}</p>
        </div>
        <div className="w-1/5 flex items-center">
          <p className="text-center">
            <span className="font-bold text-xl">
              {summary.completed_count} / {summary.total_count}
            </span><br />
            <strong className="mb-2 text-sm text-gray-500">Completed / Total </strong>
          </p>
        </div>
      </div>
    </div >
  )
}

const OralSummary = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const summary = useOralQuestionSummary(id);

  if (user.isLoading || assignment.isLoading || summary.isLoading) {
    return <Loading />;
  }

  const publishDate = moment(assignment.data.publish_date).local().format('YYYY-MM-DD HH:mm');
  const dueDate = moment(assignment.data.due_date).local().format('YYYY-MM-DD HH:mm');
  const lateDueDate = moment(assignment.data.late_due_date).local().format('YYYY-MM-DD HH:mm');

  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">{assignment.data.title}</h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="flex mt-6">
        <div className="w-3/4">

          <div className="flex flex-inline justify-between mr-6">
            <div><strong>Publishes Automatically At:</strong> {publishDate}</div>
            <div><strong>Due Date:</strong> {dueDate}</div>
            {assignment.data.allow_late_work && (
              <div><strong>Late Due Date:</strong> {lateDueDate}</div>
            )}
          </div>
          {summary.data.map((question, index) => (
            <QuestionSummary
              summary={question}
              index={index}
              key={`question-summary-${question.id}`}
            />
          ))}

          <div className="mt-12 mb-24">
            <p className="text-sm text-gray-600">
              Note: You have selected a standalone timed oral assignment. To include peer review, please return to the previous screen, select 'Individual Assignment,' and select 'Oral Assignment’ on the platform setup page.
            </p>
          </div>
        </div>
        <div className="w-1/4">
          <nav className="space-y-1" aria-label="Sidebar">
            <span className="group flex w-full"><GreenButtonLink url={`/oral/assignments/${assignment.data.id}/edit`}>Create &amp; Edit Assignment</GreenButtonLink></span>
            <span className="group flex w-full">
              <a className={buttonClasses} href={`/api/admin/oral/assignment/export/${assignment.data.id}/`}>Download Excel Results</a>
            </span>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OralSummary;
